export default {
  "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["午前"])},
  "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金曜日"])},
  "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月曜日"])},
  "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["午後"])},
  "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土曜日"])},
  "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日曜日"])},
  "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木曜日"])},
  "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["火曜日"])},
  "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水曜日"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "add_elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規追加"])},
  "add_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規追加 "])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
  "address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所は必須入力項目です"])},
  "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["システム管理者"])},
  "appName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リモートホームケアシステム"])},
  "are_you_changing_operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医療従事者を変更しますか？"])},
  "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話を終了しますか？"])},
  "auto_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動応答時間"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誕生日"])},
  "both_field_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["両方とも入力が必要です"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
  "call_to_hosp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発信を許可"])},
  "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラ"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード変更"])},
  "change_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医療従事者変更"])},
  "chat_has_terminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話が終了しました"])},
  "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード（確認）"])},
  "contact_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先"])},
  "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワード"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年月日"])},
  "date_of_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消去"])},
  "delete_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本当に削除してもよろしいでしょうか？"])},
  "dialing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダイヤル"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
  "edit_elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見守り対象者編集"])},
  "edit_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医療従事者編集"])},
  "eight_char_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8文字以上の設定が必要です"])},
  "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見守り対象者"])},
  "elderly_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見守り対象者ID"])},
  "elderly_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見守り対象者登録"])},
  "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メール"])},
  "email_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールの値が不正です"])},
  "emergency_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急連絡先"])},
  "empty_confirm_current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは必ず入力してください"])},
  "empty_current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは必ず入力してください"])},
  "empty_new_current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードは必ず入力してください"])},
  "end_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話を切る"])},
  "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日"])},
  "enter_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所を入力してください"])},
  "enter_confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを再度入力してください"])},
  "enter_contact_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先を入力してください"])},
  "enter_current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワードを入力してください"])},
  "enter_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください"])},
  "enter_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを入力してください"])},
  "enter_start_date_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初に開始日を入力する"])},
  "enter_zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号を入力してください"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女性"])},
  "five_char_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5文字以上の設定が必要です"])},
  "forced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話です！"])},
  "force_to_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強制通話機能ON"])},
  "forget_password_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを忘れた場合はシステム担当までご連絡ください。"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発信者"])},
  "fullname_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氏名の入力は必要です"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
  "generate_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード自動生成"])},
  "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医療従事者"])},
  "hospital_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス契約者登録"])},
  "hospital_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス契約者編集"])},
  "hospital_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス契約者ID"])},
  "hospital_id_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス契約者IDを入力してください"])},
  "hospital_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約事業者一覧"])},
  "hospital_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス契約事業者名"])},
  "hospital_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["病院名は必須入力項目です"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "id_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDが違います。"])},
  "incall_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話中"])},
  "incorrect_old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワードが違っています"])},
  "incorrect_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが違う"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男性"])},
  "max_five_len": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5文字に以内に設定してください"])},
  "max_seven_len": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7文字に以内に設定してください"])},
  "max_ten_len": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10文字に以内に設定してください"])},
  "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモ"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メニュー"])},
  "mic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイク"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["氏名"])},
  "name_kana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み仮名"])},
  "name_of_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス契約事業者名"])},
  "new_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス契約事業者追加"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード"])},
  "next_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約更新日"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ"])},
  "no_avail_hosp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、電話をかける相手が不在です。しばらくお待ちください。"])},
  "offline_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフライン"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
  "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発信者"])},
  "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所属"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
  "password_does_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが一致しません"])},
  "password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの入力は必要です"])},
  "password_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが不正です。"])},
  "phone_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号は必須入力項目です"])},
  "phone_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話をかける"])},
  "profile_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイコン画像"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
  "remote_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相手側の操作"])},
  "reset_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件解除"])},
  "ringing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話です！"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
  "select_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別を選んでください"])},
  "self_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分側の操作"])},
  "seven_char_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7文字以上の設定が必要です"])},
  "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スピーカー"])},
  "staff_add_form_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医療従事者追加"])},
  "staff_edit_form_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医療従事者編集"])},
  "staff_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医療従事者ID"])},
  "staff_id_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医療従事者IDが必要です"])},
  "staff_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医療従事者登録"])},
  "stand_by_screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常運用画面へ"])},
  "standby_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待ち受け画面"])},
  "start_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約開始日"])},
  "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始日"])},
  "ten_char_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10文字以上の設定が必要です"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信者"])},
  "to_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メニューへ"])},
  "updateForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像選択"])},
  "video_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話履歴"])},
  "video_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
  "video_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通話履歴"])},
  "video_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオ オフ"])},
  "video_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオ オン"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
  "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
  "zipcode_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号は必須入力項目です。"])}
}