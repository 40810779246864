import { AppRole, Person } from "@/stores/types";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { useCallStore } from "../stores/call"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/menu", //#12297 redirect to login
  },
  {
    path: "/admin/login",
    name: "admin_login",
    component: () => import("../views/AuthPage.vue"),
  },
  {
    path: "/auth/login",
    name: "hospital_login",
    component: () => import("../views/AuthPage.vue"),
  },
  {
    path: "/auth/register",
    component: () => import("../views/AuthPage.vue"),
  },
  {
    path: "/menu",
    name: "hospital_menu",
    component: () => import("../views/Hospital/HospitalMenuPage.vue"),
  },
  {
    path: "/admin/hospital",
    name: "admin_hospital",
    component: () => import("../views/Hospital/HospitalListPage.vue"),
  },
  {
    path: "/admin/hospital/registration",
    name: "admin_add_hospital",
    component: () => import("../views/Hospital/HospitalFormPage.vue"),
  },  
  {
    path: "/admin/hospital/edit/:id",
    name: "admin_edit_hospital",
    component: () => import("../views/Hospital/HospitalFormEditPage.vue"),
    props: true,
  },  
  {
    path: "/contacts/staff",
    name: "hospital_staff_list",
    component: () => import("../views/Hospital/HospitalPage.vue"),
    props: true
  },
  {
    path: "/contacts/staff/edit/:id",
    name: "hospital_edit_staff",
    component: () => import("../views/Hospital/Staff/StaffFormEditPage.vue"),
    props: true,
  },  
  {
    path: "/staff/registration",
    name: "hospital_add_staff",
    component: () => import("../views/Hospital/Staff/StaffFormPage.vue"),
  },
  {
    path: "/hospital/standby",
    name: "hospital_standby",
    component: () => import("../views/Elderly/ElderlyPage.vue"),
    props: true
  },  
  {
    path: "/hospital/changepassword",
    name: "hospital_changepassword",
    component: () => import("../views/Hospital/HospitalChangePasswordPage.vue"),
  },  
  {
    path: "/contacts/elderly",
    name: "hospital_elderly_list",
    component: () => import("../views/Elderly/ElderlyPage.vue"),
    props: true
  },
  {
    path: "/elderly/standby",
    name: "elderly_standby",
    component: () => import("../views/Elderly/ElderlyStandbyPage.vue"),
  },
  {
    path: "/elderly/registration",
    name: "hospital_add_elderly",
    component: () => import("../views/Admin/AddElderlyPage.vue"),
  },
  {
    path: "/elderly/edit/:id",
    name: "hospital_edit_elderly",
    component: () => import("../views/Admin/EditElderlyPage.vue"),
    props: true,
  },
  {
    path: "/video",
    name: "video",
    component: () => import("../views/VideoSessionPage.vue"),
  },
  {
    path: "/video/history",
    name: "hospital_history",
    component: () => import("../views/VideoSessionLogPage.vue"),
  },
  {
    path: "/initiate",
    name: "initiate",
    component: () => import("../views/VideoInitiatePage.vue"),
  },

  {
    path: "/contacts",
    name: "hospital_contacts",
    component: () => import("../components/hospital/ContactListScreen.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const callStore = useCallStore();
  let session;
  let isRouted = false;
  let currentUser = {} as any;
  const adminPages = ['admin_hospital','admin_add_hospital','admin_edit_hospital'];
  const hospitalPages = ['/menu','hospital_menu','hospital_staff_list', 'hospital_add_staff', 'hospital_edit_staff',
  'hospital_add_elderly', 'hospital_edit_elderly','hospital_elderly_list','hospital_changepassword',
  'hospital_history','hospital_standby','initiate', 'video'];
  const elderlyPages = ['hospital_elderly_list','elderly_standby','initiate', 'video']
  if (localStorage.getItem("hospital_session")) {
    session = JSON.parse(localStorage.getItem("hospital_session") as string);
    callStore.isHospitalLogged = session.isHospitalLogged;
    callStore.hospitalLogged = session.hospitalLogged;
  } else {
    session =  { isHospitalLogged: false, hospitalLogged: '', isAdmin: false }
  }

  if (localStorage.getItem("session")) {
    currentUser = JSON.parse(localStorage.getItem("session") as string);
  } else {
    currentUser.self = {} as Person;
    // currentUser.appRole = AppRole.HOSPITAL;
  }
  console.log('session',session,currentUser);
  console.log("=== ROUTE GUARD: TO ===", to.name);
  if (to.name === 'hospital_login' && !session.isHospitalLogged){
    if(session.isAdmin){
      isRouted = true;
      next({ name: 'admin_hospital' });
    } else{
      isRouted = true;
      next();
    }
  } else if (to.name === 'hospital_login' && session.isHospitalLogged){
    console.log('SULOD');
    if(currentUser.self.appRole === AppRole.ELDERLY){
      isRouted = true;
      next({ name: 'elderly_standby' });
    } else {
      isRouted = true;
      next({ name: 'hospital_menu' });
    }
  }

  if(!isRouted){
    console.log("include elderlyPages",elderlyPages.includes(to.name as string), to.name);
    if(elderlyPages.includes(to.name as string)&& currentUser.self.appRole === AppRole.ELDERLY){
      isRouted = true;
      next();
    } else {
      if(currentUser.self.appRole === AppRole.ELDERLY){
        isRouted = true;
        next({ name: 'elderly_standby' });
      } 
    }
  }
  
  if(!isRouted){
    if (to.name === 'admin_login' && !session.isAdmin){
      if(session.isHospitalLogged){
        isRouted = true;
        next({ name: 'hospital_menu' });
      } else{
        isRouted = true;
        next();
      }
    } else if (to.name === 'admin_login' && session.isAdmin){
      isRouted = true;
      next({ name: 'admin_hospital' });
    }
  }

  if(!isRouted){
    console.log("include hospitalPages",hospitalPages.includes(to.name as string), to.name);
    if (hospitalPages.includes(to.name as string) && session.isHospitalLogged){
      isRouted = true;
      next();
    } else {
      if (session.isAdmin){
        if (adminPages.includes(to.name as string)){
          isRouted = true;
          next();
        } else {
          isRouted = true;
          next({ name: 'admin_login' });
        }
      } else {
        isRouted = true;
        next({ name: 'hospital_login' });
      }
    }
  }
  
  if(!isRouted){
    console.log("include adminpages",adminPages.includes(to.name as string), to.name);
    if (adminPages.includes(to.name as string) && session.isAdmin){
      isRouted = true;
      next();
    } else {
      if (session.isHospitalLogged){
        if (hospitalPages.includes(to.name as string)){
          isRouted = true;
          next();
        } else {
          isRouted = true;
          next({ name: 'hospital_login' });
        }
      } else {
        isRouted = true;
        next({ name: 'admin_login' });
      }
    }
  }
 
  
  //IMPORTANT DO NO REMOVE
  // next();
});

export default router;
