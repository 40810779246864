export default {
  "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AM"])},
  "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "PM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM"])},
  "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
  "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "add_elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Elderly"])},
  "add_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Staff"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address must not be empty!"])},
  "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "appName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Home Care System"])},
  "are_you_changing_operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you changing the operator?"])},
  "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "auto_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto answering time"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACK"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "both_field_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both fields are required!"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])},
  "call_to_hosp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call to hospital"])},
  "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMERA"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "change_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Staff"])},
  "chat_has_terminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The video chat is terminated."])},
  "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
  "contact_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Number"])},
  "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "date_of_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "delete_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this user?"])},
  "dialing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIALING"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "edit_elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Elderly"])},
  "edit_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Staff"])},
  "eight_char_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be at least 8 characters long"])},
  "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELDERLY"])},
  "elderly_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elderly ID"])},
  "elderly_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elderly List"])},
  "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "email_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address must be valid!"])},
  "emergency_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Contact"])},
  "empty_confirm_current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new Password field must not be empty!"])},
  "empty_current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password field must not be empty!"])},
  "empty_new_current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password field must not be empty!"])},
  "end_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["END CALL"])},
  "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "enter_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Address"])},
  "enter_confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter confirm new Password"])},
  "enter_contact_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Contact Number"])},
  "enter_current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Current Password"])},
  "enter_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Email address"])},
  "enter_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Password"])},
  "enter_start_date_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Start Date First"])},
  "enter_zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Zip Code"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["female"])},
  "five_char_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be at least 5 characters long"])},
  "forced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RINGING"])},
  "force_to_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force to answer"])},
  "forget_password_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you forget your password please contact"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "fullname_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name is required!"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "generate_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Password"])},
  "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOSPITAL"])},
  "hospital_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital Add"])},
  "hospital_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital Edit"])},
  "hospital_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital ID"])},
  "hospital_id_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Service Contractor ID"])},
  "hospital_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital List"])},
  "hospital_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOSPITAL NAME"])},
  "hospital_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital Name must not be empty!"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "id_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*ID is invalid!"])},
  "incall_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elderly is busy at the moment, Please try your call later."])},
  "incorrect_old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect Old Password!"])},
  "incorrect_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect password"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male"])},
  "max_five_len": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum length allowed is 5"])},
  "max_seven_len": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum length allowed is 7"])},
  "max_ten_len": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum length allowed is 10"])},
  "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "mic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIC"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "name_kana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name (KANA)"])},
  "name_of_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of Hospital"])},
  "new_hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Hospital"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "next_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Contract"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
  "no_avail_hosp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no available Hospital Staff at the moment. Please try again later."])},
  "offline_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elderly is not available at the moment, Please try your call later."])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
  "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
  "password_does_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password does not match!"])},
  "password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required!"])},
  "password_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Password is invalid!"])},
  "phone_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number must not be empty!"])},
  "phone_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone to"])},
  "profile_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Photo"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGISTER"])},
  "remote_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Control"])},
  "reset_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "ringing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RINGING"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "select_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Gender"])},
  "self_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Control"])},
  "seven_char_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be at least 7 characters long"])},
  "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPEAKER"])},
  "staff_add_form_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff Form Add Page"])},
  "staff_edit_form_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff Form Edit Page"])},
  "staff_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff ID"])},
  "staff_id_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff ID is required!"])},
  "staff_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff List"])},
  "stand_by_screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Stand-by Screen"])},
  "standby_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standby Screen"])},
  "start_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Contract"])},
  "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "ten_char_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be at least 10 characters long"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "to_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Menu"])},
  "updateForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPLOAD"])},
  "video_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Chat History"])},
  "video_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "video_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video History"])},
  "video_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIDEO OFF"])},
  "video_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIDEO ON"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YES"])},
  "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
  "zipcode_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code must not be empty!"])}
}