export interface Person {
  // uuid: string;
  id: string;
  fullname: string;
  hospital?: string;
  displayName?: string;
  phone: string;
  address: string;
  age: number;
  gender?: any;
  photo: string;
  online: boolean;

  //password and organization is added for add staff - #12338
  personId?: string; //user inputted data
  password?: string;
  organization?: string;

  // new add: force answer
  forceAnswer?: boolean;  
  //new add: call to hospital
  callToHospital?: boolean;

  // new add: auto answer time & memo
  autoAnswerTime?: number;
  memo?: string;
  nameKana?: string;

  // new add: birthday #12613
  birthday?: string

  // role and device
  appRole?: AppRole;
  deviceStatus?: DeviceStatus;
  isHospital?: boolean;

  // zoom participant id
  zoom_user_id: number;
  session_token: string;

  // controls
  isCameraMute?: boolean;
  isMicMute?: boolean;
  isSpeakerMute?: boolean;
  isSharingScreen?: boolean;

  // === Zoom Session Invite Details ===
  sessionInvite?: Session
}

//hospital interface needed for add hospital - #12317
//for team's confirmation
export interface Hospital{
  id?: string;
  hospitalId?: string;
  hospitalName: string;
  zipCode?: string;
  address: string;
  phone: string;
  emailAddress: string;
  password: string;
  startContract: string;
  nextContract: string;
}

export interface User {
  uid: string;
  email?: string;
  displayName?: string
  phoneNumber?: string;
  photoUrl?: string;
  password: string;
}

export interface Session {
  id?: string,
  providerId: string,
  topic: string,
  token: string,
  password: string,
  status: SessionStatus,

  callStart: number,
  callEnd?: number,
  calledParty?: {
    id: string,
    fullname: string
  }
  callingParty?: {
    id: string,
    fullname: string
  }
  idleTimeout?: number,
  otherParty?: {
    id: string,
    fullname: string,
    appRole: AppRole,
  }
  sessionRejectID? : string,
  sessionHostID? : string,
  zoomHostUserID?: number,
  zoomOtherUserID?: number,
}

export enum ZoomCommand {
  'COMMAND_READY' = 'COMMAND_READY',
  'END_CALL' = 'END CALL',
  'FORCE_ACCEPT_CALL' = 'FORCE ACCEPT CALL',

  'CALL_ACCEPTED' = 'CALL_ACCEPTED',
  'CALL_TERMINATED' = 'CALL_TERMINATED',
  'CALL_CANCELLED' = 'CALL_CANCELLED',

  'START_VIDEO' = 'START VIDEO',
  'START_MIC' = 'START MIC',

  'MUTE_VIDEO' = 'MUTE VIDEO',
  'MUTE_MIC' = 'MUTE MIC',

  'TOGGLE_VIDEO' = 'TOGGLE VIDEO',
  'TOGGLE_MIC' = 'TOGGLE MIC'
}

// for VideoInitiatePage, specify call details
export interface CallDetails {
  name: string;
  hospital?: string;
  photo: string;
}

export enum SessionStatus {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  CALL_CANCELLED = "CALL_CANCELLED",
  COMPLETED = "COMPLETED",
}

export enum DeviceStatus {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  RINGING = "RINGING",
  DIALING = "DIALING",
  INCALL = "IN CALL",
  FORCED = "FORCED"
}

export enum OutboundCallStatus {
  STANDBY = "STANDBY",
  INITIATING = "INITITAING",
  INPROGRESS = "IN PROGRESS",
  COMPLETED = "COMPLETED",
}

export enum FinalCallStatus {
  BUSY = "BUSY",
  NOANSWER = "NO ANSWER",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
}

export enum AppRole {
  HOSPITAL = "HOSPITAL",
  ELDERLY = "ELDERLY",
  STAFF = "STAFF",
  ADMIN = "ADMIN"
}

export enum AdminActions {
  NONE = "NONE",
  ADDELDERLY = "ADDELDERLY",
  EDITELDERLY = "EDITELDERLY",
}


