import { AdminActions, Session } from "./types";
import { defineStore } from "pinia";
import router from "../router";
import { getStorage, ref, getDownloadURL, uploadBytes  } from "firebase/storage";
import {
  videocamOutline,
  videocamSharp,
  videocamOffOutline,
  videocamOffSharp,
  micOffOutline,
  micOffSharp,
  micOutline,
  micSharp,
} from "ionicons/icons";

import {
  AppRole,
  DeviceStatus,
  Person,
  SessionStatus,
  ZoomCommand,
  Hospital
} from "@/stores/types";
import { faker } from "@faker-js/faker";
import ZoomVideo, {
  CommandChannel,
  Participant,
  Stream,
  VideoClient,
  VideoQuality,
} from "@zoom/videosdk";
import KJUR from "jsrsasign";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  onSnapshot,
  query,
  addDoc,
  deleteDoc,
  updateDoc,
  getDoc,
  getDocs,
  where
} from "firebase/firestore";

import { getPlatforms, isPlatform } from "@ionic/vue";
import dayjs from "dayjs";
import { timeStamp } from "console";
// needed for routing in remote commands
// const router = useRouter();
// const route = useRoute();

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAGVy8FvyDriMKycthBn5fmcLK0d2iCS9o",
//   authDomain: "mt-zoom.firebaseapp.com",
//   projectId: "mt-zoom",
//   storageBucket: "mt-zoom.appspot.com",
//   messagingSenderId: "1001240533496",
//   appId: "1:1001240533496:web:d2850557a289a43808de0d",
//   measurementId: "G-YFX6RMPM4S",
// };

console.log("=== FIREBASE API KEY ===", process.env.VUE_APP_FB_API_KEY);
console.log("=== FIREBASE PROJECT ID ===", process.env.VUE_APP_FB_PROJECT_ID);

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FB_APP_ID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application

// === zoom client (this must be in main thread) ====
// const zoomClient = ZoomVideo.createClient();
// zoomClient.init("en-US", "Global", { enforceMultipleVideos: true });
// console.log("=== INITIALIZED ZOOM CLIENT ===", zoomClient);

// const zoomStream = zoomClient.getMediaStream();

// console.log("=== ZOOM STREAM ===", zoomStream);

// === zoom command channel ===
// ex: zoomCmd(payload,userId) , omit userId to send to All parties
// const zoomCmd = zoomClient.getCommandClient();

// use this Dummy Session for now
const dummySession = {
  // topic : `Session with ${calledParty.fullname}`,
  // topic: faker.word.adjective() + ' ' + faker.word.noun(),
  topic: "NEIL ZOOM",

  // providerId: "GENERAL HOSPITAL",
  providerId: "総合病院",
  // password: faker.random.alphaNumeric(10),
  password: "pass123",
  status: SessionStatus.STARTED,
  token: "WHERE DO WE NEED THIS",
} as Session;

// important: this is use to track the correct selfViewType (canvas, video)
let SelfViewLocked = false;

export const useCallStore = defineStore("call", {
  state: () => ({
    appVersion: "",
    // ZOOM COMMAND CHANNEL ready
    // we should only execute commands once this is true / ready
    // should set FALSE when starting a session
    // wait for CALLBACK to set TRUE
    isCommandChannelReady: false,

    // application role
    appRole: AppRole.HOSPITAL,
    // appRole: AppRole.ELDERLY,

    // self View Type canvas or video
    selfViewType: "video",

    // Zoom
    zoomSDK_KEY: process.env.VUE_APP_ZOOM_VIDEO_SDK_KEY,
    zoomSDK_SECRET: process.env.VUE_APP_ZOOM_VIDEO_SDK_SECRET,
    // zoomClient: VideoClient,
    // zoomStream: Stream,
    zoomClient: VideoClient,
    zoomStream: Stream,
    zoomCmd: CommandChannel,


    // DOM elements
    videoCanvas: {} as HTMLCanvasElement,
    selfCanvas: {} as HTMLCanvasElement,
    selfVideo: {} as HTMLVideoElement,

    // set the size of the canvas here, aspect ration shoudl be 16:9
    // we add 20% to the height for the self video
    canvasWidth: 640,
    canvasHeight: 360,

    selfViewWidth: Math.round((360 * 0.2 * 16) / 9),
    selfViewHeight: Math.round(360 * 0.2),

    dummySession: dummySession,

    //for hospital login #12297
    // hospitalSession: {} as Hospital,
    isAdmin: false,
    isHospitalLogged: false,
    hospitalLogged: {} as Hospital,

    // yourself, relative, can be elderly or hosptial staff
    self: {} as Person,

    // these are absolute references, calling is always the one initiating the call
    callingParty: {} as Person,
    calledParty: {} as Person,

    // contact list
    contacts: [] as Array<Person>,

    // participants in call
    callParty: [] as Array<Person>,

    users: {},

    // Zoom call sessions
    sessions: [] as Array<Session>,

    hospitals: [] as Array<Hospital>,
    // current zoom session
    currentSession: {} as Session,

    // TEMPORARY
    zoomParticipants: [] as Array<Participant>,

    // ringtone
    ringtone: new Audio(),

    // boolean for isPlaying
    isRingtonePlaying: false,

    // CONTROLS:

    isWebCamOn: false,
    remoteIsWebCamOn: false,
    isMuted: false,
    remoteIsMuted: false,
    micLogoIOS: micOutline,
    micLogoA: micSharp,
    videoLogoIOS: videocamOffOutline,
    videoLogoA: videocamOffSharp,
    pickingMode: false, //filtering contact list picking and calling mode
    isLoaded: false,
    elderIsStandby: true,
    isPeerVideoOn: false,
    hospitalIsStandby: false,

    // used for rendering video
    isSupportOffscreenCanvas: false,
    // Navbar header
    staffNavBarHeader: "Menu",

    // ADMIN/STAFF ACTIONS
    adminActions: AdminActions.NONE,

    // Used for terminate modal
    isCallTerminated: false,
    autoAnswerId : setTimeout(() => console.log("data success"), 0),

    ts : [] as Array<number>,
    idx: 0,
    reloadPage : false,
    isLoading : false,
    callLoading: false,
    cmdMsgTs: 0,
    cmdMsgTxt: '',
    evtPayload : {} as any,
  }),

  getters: {
    // Zoom
    zoomSDKKey: (state) => state.zoomSDK_KEY,
    zoomSDKSecret: (state) => state.zoomSDK_SECRET,
    // zoomVideoClient: (state) => state.zoomClient,
    // zoomVideoStream: (state) => state.zoomStream,

    // quick SELF reference (hint you can WATCH this)
    mySelf: (state) => state.self,
    myDeviceStatus: (state) => state.self.deviceStatus,
    myAppRole: (state) => state.appRole,
    isOurCallTerminated: (state) => state.isCallTerminated,

    allContacts: (state) =>
      state.contacts
        .filter(function (data) {
          if (!state.pickingMode) {
            return data.fullname && data.appRole !== state.appRole;
          }
          return data.fullname;
        })
        .sort((a, b) => {
          const an = a.fullname.toLowerCase();
          const bn = b.fullname.toLowerCase();

          if (an < bn) {
            return -1;
          }

          if (an > bn) {
            return 1;
          }

          return 0;
        }),
    allCallParty: (state) => state.callParty,

    // depends on who is self
    otherParty: (state) =>
      state.self.id == state.callingParty.id
        ? state.calledParty
        : state.callingParty,

    allStaff: (state) => {
      const staff = state.contacts
        .filter((i) => i.appRole === AppRole.HOSPITAL)
        .sort((a, b) => {
          const an = a.fullname.toLowerCase();
          const bn = b.fullname.toLowerCase();

          if (an < bn) {
            return -1;
          }

          if (an > bn) {
            return 1;
          }

          return 0;
        });

      return [
        ...staff
          .reduce((map, obj) => {
            map.set(obj.id, obj);
            return map;
          }, new Map())
          .values(),
      ];
    },

    allStaffHospital: (state) => {
      const staff = state.contacts
        .filter((i) => i.appRole === AppRole.STAFF)
        .sort((a, b) => {
          const an = a.fullname.toLowerCase();
          const bn = b.fullname.toLowerCase();

          if (an < bn) {
            return -1;
          }

          if (an > bn) {
            return 1;
          }

          return 0;
        });

      return [
        ...staff
          .reduce((map, obj) => {
            map.set(obj.id, obj);
            return map;
          }, new Map())
          .values(),
      ];
    },

    allHospitalStaff: (state) => {
      const staff = state.contacts
        .filter(
          (i) =>
            i.appRole === AppRole.HOSPITAL &&
            i.deviceStatus === DeviceStatus.ONLINE &&
            i.isHospital === true
        )
        .sort((a, b) => {
          const an = a.fullname.toLowerCase();
          const bn = b.fullname.toLowerCase();

          if (an < bn) {
            return -1;
          }

          if (an > bn) {
            return 1;
          }

          return 0;
        });

      return [
        ...staff
          .reduce((map, obj) => {
            map.set(obj.id, obj);
            return map;
          }, new Map())
          .values(),
      ];
    },

    allElderly: (state) => {
      const elderly = state.contacts
        .filter((i) => i.appRole === AppRole.ELDERLY)
        .sort((a, b) => {
          const an = a.fullname.toLowerCase();
          const bn = b.fullname.toLowerCase();

          if (an < bn) {
            return -1;
          }

          if (an > bn) {
            return 1;
          }

          return 0;
        });

      return [
        ...elderly
          .reduce((map, obj) => {
            map.set(obj.id, obj);
            return map;
          }, new Map())
          .values(),
      ];
    },
    allHospital: (state) => {
      const hospital = state.hospitals.sort((a, b) => {
        const an = a.hospitalName.toLowerCase();
        const bn = b.hospitalName.toLowerCase();

        if (an < bn) {
          return -1;
        }
        if (an > bn) {
          return 1;
        }
        return 0;
      });
      return [
        ...hospital
          .reduce((map, obj) => {
            map.set(obj.id, obj);
            return map;
          }, new Map())
          .values(),
      ];
    },

    allVideoSession: (state) => {
      
      const videoSession = state.sessions
      .filter((obj: any) => obj.calledParty != null && obj.callingParty != null)

      return [
        ...videoSession
          .reduce((map, obj) => {
            map.set(obj.id, obj);
            return map;
          }, new Map())
          .values(),
      ];
    },

  },

  actions: {
    async initZoom() {
      // === zoom client (this must be in main thread) ====
      
      const videoSDKLibDir = './@zoom/videosdk/dist/lib';
      const zmClientInitParams = {
        language: 'en-US',
        // dependentAssets: `${videoSDKLibDir}`,
        dependentAssets: `CDN`,
        initOptions: { enforceMultipleVideos: true }
    };
      ZoomVideo.destroyClient();
      this.zoomStream = Stream;
      this.zoomCmd = CommandChannel;
      this.zoomClient = VideoClient;
      console.log('=== ZOOM CLIENT, STREAM, CMDCHANNEL ===', this.zoomClient, this.zoomStream, this.zoomCmd );
      
      // this.zoomClient.init("en-US", "Global", { enforceMultipleVideos: true });
      this.zoomClient = ZoomVideo.createClient();
      
      this.zoomClient.init(
        zmClientInitParams.language,
        zmClientInitParams.dependentAssets,
        zmClientInitParams.initOptions,
      );
      this.destroyClientEventListeners();
      console.log("=== INITIALIZED ZOOM CLIENT ===", this.zoomClient);
    },

    async getVersion(fileURL: string) {
      const response = await fetch(fileURL);
      const data = await response.text();
      console.log(data);
      if (data) {
        this.appVersion = data;
      } else {
        this.appVersion = "";
      }
    },

    setZoomVideoCanvas(canvas: HTMLCanvasElement) {
      this.videoCanvas = canvas;
    },

    setSelfCanvas(canvas: HTMLCanvasElement) {
      this.selfCanvas = canvas;
    },

    setSelfVideo(video: HTMLVideoElement) {
      this.selfVideo = video;
    },

    // set yourself can be Elderly or Doctor depending on store.AppRole
    setSelf(payload: Person) {
      this.self = payload;

      // initially ONLINE?
      if(this.self.id){
        console.log('sulod', this.self.id)
        this.self.deviceStatus = DeviceStatus.ONLINE;
        this.stopRingtone(); // force stop

        const newPayload = {
          deviceStatus: DeviceStatus.ONLINE,
          sessionInvite: {} as Session,
        }

        // update Firestore
        if (this.self.appRole == AppRole.HOSPITAL) {
          Object.assign(newPayload, {isHospital: true})
        }

        this.updateUser(payload.id as string, newPayload);

        // save to local storage, to recall later (debugging)
        // localStorage.setItem(
        //   "session",
        //   JSON.stringify({ appRole: this.appRole, self: this.self })
        // );
    }

    // save to local storage, to recall later (debugging)
    localStorage.setItem(
      "session",
      JSON.stringify({ appRole: this.appRole, self: this.self })
    );


    },

    initializeAudioVideo() {
      if (this.self.appRole === AppRole.HOSPITAL) {
        this.isMuted = false;
        this.micLogoIOS = micOutline;
        this.micLogoA = micSharp;
        console.log("AppRole", this.micLogoA);
      } else {
        this.micLogoIOS = micOffOutline;
        this.micLogoA = micOffSharp;
      }
    },

    setCallingParty(payload: Person) {
      this.callingParty = payload;
    },

    setLoading(payload: boolean) {
      this.isLoading = payload;
    },

    // ad to contact list
    addContact(payload: Person) {
      // for demo purpose set first person as SELF
      if (payload == ({} as Person)) {
        return;
      }
      if (this.contacts.length == 0 && this.self.appRole == AppRole.ELDERLY) {
        this.setSelf(payload);
      }
      this.contacts.push(payload);
    },

    addParty(payload: Person) {
      this.callParty.push(payload);
    },

    /* ****************************************************
     *               GENERATE JWT TOKEN                 *
     * This is to create a token for joining/creating   *
     * session.                                         *
     *  PARAMS :                                        *
     *  - Person                                        *
     *  - Session                                       *
     * ************************************************ */
    generateJWTSignature(
      user: Person,
      session: Session,
      isHost = false
    ): string {
      const role: number = isHost ? 1 : 0;
      console.log("=== IS HOST ", role);
      const iat = Math.round((new Date().getTime() - 30000) / 1000);
      const exp = iat + 60 * 60 * 2;
      const oHeader = { alg: "HS256", typ: "JWT" };

      const oPayload = {
        app_key: this.zoomSDK_KEY,
        tpc: session.topic,
        role_type: role,
        session_key: session.password,
        user_identity: user.fullname,
        iat: iat,
        exp: exp,
      };
      const sHeader = JSON.stringify(oHeader);
      const sPayload = JSON.stringify(oPayload);
      const sdkJWT = KJUR.KJUR.jws.JWS.sign(
        "HS256",
        sHeader,
        sPayload,
        this.zoomSDK_SECRET
      );

      return sdkJWT;
    },

    // === ZOOM EVENT LISTENER ===
    initClientEventListeners: async function () {
      
      console.log(`=== ZOOM EVENT LISTENER ===`);
     
      this.zoomClient.on("user-added", (payload) => {
        if(!(Object.keys(this.evtPayload).includes('user-added'))){
          this.evtPayload['user-added'] = '';
        }
  
        
        if(this.evtPayload['user-added'] !== JSON.stringify(payload)){
          console.log('=== COMPARING EVENT PAYLOAD ===', 
            JSON.stringify(this.evtPayload['user-added']) === 
            JSON.stringify(payload) ? true : false
          );
          console.log('=== user-added (payload) ===', JSON.stringify(payload));
          
          this.evtPayload['user-added'] = JSON.stringify(payload);

          if(this.self.id === this.self.sessionInvite?.sessionHostID &&  location.href.split('/').pop() !== 'video'){
            // console.log("=== USER JOIN THE SESSION ===", this.self.sessionInvite.calledParty?.fullname);
            this.stopRingtone();
            router.push({ path: "/video" });
            console.log(" === ROUTE ===", "/video");
            this.updateUser(this.self.id as string, {
              deviceStatus: DeviceStatus.INCALL,
            });
            
          }
          if(this.self.id === this.currentSession.sessionHostID){
            payload?.forEach((party) => {
              if(!party.isHost){
                this.currentSession.zoomOtherUserID = party.userId;
              }
            });
          }
        }
        
        
      });

      this.zoomClient.on("user-removed", async (payload) => {
        if(!(Object.keys(this.evtPayload).includes('user-removed'))){
          this.evtPayload['user-removed'] = '';
        }
        if(this.evtPayload['user-removed'] !== JSON.stringify(payload)){
          this.evtPayload['user-removed'] = JSON.stringify(payload);
          console.log('=== user-removed (payload) ===', JSON.stringify(payload));
        }// console.log(`=== Zoom User Removed ===`, JSON.stringify(payload));
      });

      this.zoomClient.on("command-channel-status", async (payload) => {
        if(!(Object.keys(this.evtPayload).includes('command-channel-status'))){
          this.evtPayload['command-channel-status'] = '';
        }
        if(this.evtPayload['command-channel-status'] !== JSON.stringify(payload)){
          this.evtPayload['command-channel-status'] = JSON.stringify(payload);
          console.log('=== command-channel-status (payload)===', JSON.stringify(payload));
          if (payload.toUpperCase() == "CONNECTED") {
            this.isCommandChannelReady = true;
            // notify other side
            // setTimeout(() => {
            //   zoomCmd.send(ZoomCommand.COMMAND_READY);
            // }, 1000);
          } else {
            this.isCommandChannelReady = false;
            console.warn(
              "=== COMMAND CHANNEL (now) ===",
              this.isCommandChannelReady ? "READY" : "NOT READY"
            );
          }
        }
      });

      this.zoomClient.on("command-channel-message", async (payload) => {
        if(!(this.cmdMsgTs === payload.timestamp && this.cmdMsgTxt === payload.text)){
          console.log("=== command-channel-message ===", JSON.stringify(payload));
          this.cmdMsgTs = payload.timestamp; 
          this.cmdMsgTxt = payload.text;
          if (payload.text == ZoomCommand.COMMAND_READY) {
            this.isCommandChannelReady = true;
            // console.log(
            //   "=== COMMAND CHANNEL (now) === ",
            //   this.isCommandChannelReady ? "READY" : "NOT READY"
            // );
          }

          if (payload.text === ZoomCommand.CALL_ACCEPTED) {  
            // if(this.self.id === this.self.sessionInvite?.sessionHostID &&  location.href.split('/').pop() !== 'video'){
            //   this.stopRingtone();
            //   router.push({ path: "/video" });
            //   console.log(" === ROUTE ===", "/video");
            //   this.updateUser(this.self.id as string, {
            //     deviceStatus: DeviceStatus.INCALL,
            //   });
              
            // }
          }

          if (payload.text == ZoomCommand.CALL_CANCELLED) {
            // console.log(
            //   "=== CALL WAS CANCELLED === (by)",
            //   JSON.stringify(this.self.sessionInvite?.otherParty)
            // );

            this.cancelCall();

            console.log(" === ROUTE ===", this.self.appRole);
            this.appRole = AppRole.ELDERLY;

            if (this.self.appRole == AppRole.HOSPITAL) {
              router.push({ path: "/contacts/elderly" });
            } else {
              router.push({ path: "/elderly/standby" });
            }
          }

          if (payload.text == ZoomCommand.TOGGLE_VIDEO) {
            // console.log(
            //   "=== TOGGLE VIDEO === (by, self)",
            //   JSON.stringify(this.self.sessionInvite?.otherParty),
            //   JSON.stringify(this.self)
            // );

            this.myToggleVideo(this.selfCanvas, this.selfVideo);
          }

          if (payload.text == ZoomCommand.TOGGLE_MIC) {
            // console.log(
            //   "=== TOGGLE MIC === (by)",
            //   JSON.stringify(this.self.sessionInvite?.otherParty)
            // );

            this.toggleAudio();
          }

          if (payload.text == ZoomCommand.CALL_TERMINATED) {
            // console.clear();
            // console.log(
            //   "=== CALL WAS TERMINATED === (by)",
            //   payload,
            //   this.self.fullname
            // );
            this.isCallTerminated = true;

            if (this.isWebCamOn) {
              await this.myToggleVideo(this.selfCanvas, this.selfVideo);
              await this.zoomStream.clearVideoCanvas(this.videoCanvas, "blue");
            }

            await this.zoomStream.stopAudio();

            try {
              await this.zoomClient.leave().then(() => {
                console.log('=== LEAVE THEN : DESTROYING ZOOMVIDEO CLIENT');
                this.reloadPage = !this.reloadPage;
                ZoomVideo.destroyClient();
                // INITIALIZE ZOOM INSTANCES TO NEW
                this.zoomClient = VideoClient;
                this.zoomStream = Stream;
                this.zoomCmd = CommandChannel;
                this.isCommandChannelReady = false;
                this.evtPayload = {};
                this.ts = [];
              });
            } catch(error){
              console.log('ERROR ON ENDING THE CALL', error);
            }
            
            this.isMuted = false;
            this.remoteIsMuted = false;
            
            this.updateUser(this.self.id as string, {
              deviceStatus: DeviceStatus.ONLINE,
              sessionInvite: {} as Session,
            });

            if (this.self.appRole == AppRole.ELDERLY) {
              console.log("ROUTER:", router);
              router.push({ path: "/elderly/standby" });
            }
          }

          if (payload.text == ZoomCommand.END_CALL) {
            if (this.self.id === this.self.sessionInvite?.sessionHostID){
              try {
                this.isCallTerminated = true;
                await this.zoomCmd.send(ZoomCommand.CALL_TERMINATED, this.currentSession.zoomOtherUserID).then(() => {
                  setTimeout(async () => {
                    await this.terminateCall(this.selfCanvas, this.selfVideo);
                    this.ringtone = new Audio();
                  }, 2000)
                });
                if (this.self.appRole == AppRole.ELDERLY) {
                  this.reloadPage = !this.reloadPage;
                  router.push({ path: "/elderly/standby" });
                  // location.reload();
                }
              } catch (error) {
                console.log("ERROR ON EJECTING USER", error);
              }
            }
          }
        }
      });

      this.zoomClient.on("current-audio-change", async (payload) => {
        if(!(Object.keys(this.evtPayload).includes('current-audio-change'))){
          this.evtPayload['current-audio-change'] = '';
        }
        if(this.evtPayload['current-audio-change'] !== JSON.stringify(payload)){
          this.evtPayload['current-audio-change'] = JSON.stringify(payload);
          console.log("=== current-audio-change ===", JSON.stringify(payload));
        }
      });

      this.zoomClient.on("auto_play_audio_failed", async (payload) => {
        if(!(Object.keys(this.evtPayload).includes('auto_play_audio_failed'))){
          this.evtPayload['auto_play_audio_failed'] = '';
        }
        if(this.evtPayload['auto_play_audio_failed'] !== JSON.stringify(payload)){
          this.evtPayload['auto_play_audio_failed'] = JSON.stringify(payload);
          console.log("=== auto_play_audio_failed ===", JSON.stringify(payload));
        }
      });

      this.zoomClient.on("peer-video-state-change", async (payload) => {
        if(!(Object.keys(this.evtPayload).includes('peer-video-state-change'))){
          this.evtPayload['peer-video-state-change'] = '';
        }
        if(this.evtPayload['peer-video-state-change'] !== JSON.stringify(payload)){
          this.evtPayload['peer-video-state-change'] = JSON.stringify(payload);
          console.log("=== peer-video-state-change ===", JSON.stringify(payload));
          const { action, userId } = payload;
          if (action === "Start") {
            // this.toggleVideo(true, this.videoCanvas, userId, 1080, 540);
            // console.log("=== START RENDER VIDEO ===", userId);
            // set video on from other party to ON
            this.isPeerVideoOn = true;
            
            let h = Math.round(this.canvasHeight * 0.8);
            let w = Math.round((this.canvasHeight * 0.8 * 16) / 9);
            
            if(this.self.appRole === AppRole.ELDERLY){
              h = Math.round(this.canvasHeight * 1.0);
              w = Math.round((this.canvasHeight * 1.0 * 16) / 9);
            }
          

            // Center the remote view on hospital side
            const x = this.self.appRole == AppRole.HOSPITAL ? 60 : 0;
            const y = this.self.appRole == AppRole.HOSPITAL ? this.selfViewHeight : 0;
            // console.log("=== PEER VIEW SIZE (w,h,x,y)===", w, h, x, y);
            try {
              // if(this.self.appRole === AppRole.ELDERLY){
                await this.zoomStream.renderVideo(
                  this.videoCanvas,
                  userId,
                  w,
                  h,
                  x,
                  y,
                  VideoQuality.Video_360P
                );
              // } else {
              //   await this.zoomStream.renderVideo(
              //     this.videoCanvas,
              //     userId,
              //     this.canvasWidth,
              //     this.canvasHeight,
              //     0,
              //     0,
              //     VideoQuality.Video_360P
              //   );
              // }
              
            } catch (error) {
              console.error("=== RENDER VIDEO ERROR ===", JSON.stringify(error));
            }
          } else if (action === "Stop") {
            // this.toggleVideo(false, this.videoCanvas, userId, 1080, 540);
            // console.log("=== STOP RENDER VIDEO ===", userId);
            // set video on from other party to ON
            if(this.isPeerVideoOn){
              this.isPeerVideoOn = false;
              await this.zoomStream.stopRenderVideo(this.videoCanvas, userId);
              await this.zoomStream.clearVideoCanvas(this.videoCanvas, "blue");
            }
          
            // zoomStream.stopVideo();
          }
        }
      });

      
    },

    // === DESTROY ZOOM EVENT LISTENER ===
    destroyClientEventListeners: async function () {
      this.zoomClient.off("user-added", () => {
        console.log('=== OFF LISTENER user-added');
      });

      this.zoomClient.off("user-removed", () => {
        console.log('=== DESTROYED user-removed');
      });

      this.zoomClient.off("current-audio-change", () => {
        console.log('=== DESTROYED current-audio-change');
      });

      this.zoomClient.off("auto_play_audio_failed", () => {
        console.log('=== DESTROYED auto_play_audio_failed');
      });

      this.zoomClient.off("peer-video-state-change", () => {
        console.log('=== DESTROYED peer-video-state-change');
      });

      this.zoomClient.off("command-channel-status", () => {
        console.log('=== DESTROYED command-channel-status');
      });

      this.zoomClient.off("command-channel-message", () => {
        console.log('=== DESTROYED command-channel-message');
      });
    },



    // this is not initiating session but SETTING session?
    setSession(session: Session) {
      console.log(
        "=== SET SESSION ===",
        session,
      );
      this.currentSession = session;
    },

    // this basically set deviceStatus to DIALING and RINGING
    async initiateCall(calledParty: Person, session: Session) {
      // === SESSION CREATION SHOULD BE HERE, if multi session === ???

      // === GENERATE SESSION ====
      // 1. this will be used to JOIN the initiating Party (SELF)
      // 2. this will be send in the sessionInvite to the other Party
      // 3. when ACCEPT_CALL must use the session in the SELF.sessionInvite
      //

      // === initialize Zoom Client, Stream and Command ===
      this.initZoom();

      const currentDate = new Date();
      const timestamp = currentDate.getTime();

      const mySession = {} as Session;
      mySession.topic = "TOPIC-" + faker.datatype.uuid();
      mySession.providerId = this.hospitalLogged.hospitalName;
      mySession.password = faker.random.alphaNumeric();
      mySession.status = SessionStatus.STARTED;

      mySession.callStart = timestamp;

      // TO BE UPDATE WHEN CALL IS TERMINATED
      mySession.callEnd = 0;
      mySession.calledParty = {
        id : calledParty.id,
        fullname : calledParty.fullname
      }
      mySession.callingParty = {
        id : this.self.id,
        fullname : this.self.fullname
      }
      mySession.idleTimeout = 5; //Session will Time after this minutes inactitivity
      mySession.sessionHostID = this.self.id;
      
      // just override for now!
      session = mySession;

      console.warn(
        "=== COMMAND CHANNEL ===",
        this.isCommandChannelReady ? "READY" : "NOT READY"
      );

      // this does not add Parties to Zoom Session!!!
      console.log("=== INITIATE CALL === ....calledParty", calledParty);

      // === zoom session token for calling party
      // - user.fullname
      // - session.topic
      // - session.password
      //
      // IN this case this is for the CALLING PARTY / SELF
      // the other Party ACCEPTING the CALL must generate also their token
      const session_token = this.generateJWTSignature(this.self, session, true);
      
      this.self.session_token = session_token;

      // set the called party, calling party is self
      this.calledParty = calledParty;
      this.callingParty = this.self;

      // === JOIN THE CALLING PARTY / SELF TO ZOOM SESSION
      // - topic
      // - token (from user above)
      // - username
      // - password (session)
      let zoomHostUserID;
      await this.joinZoomSession(this.self, session).then((zoomUserID)=> {
        console.error("SESSION USER ID : this.zoomClient.sessionHost().userId", zoomUserID)
        zoomHostUserID = zoomUserID;
      });
      session.zoomHostUserID = zoomHostUserID;

      this.setSession(session);

      // update device status
      this.callingParty.deviceStatus = DeviceStatus.DIALING;
      this.callParty.push(this.callingParty);

      this.calledParty.deviceStatus = DeviceStatus.RINGING;
      this.callParty.push(this.calledParty);


      // update contact device status
      let idx = this.contacts.findIndex(
        (item) => item.id == this.callingParty.id
      );
      this.contacts[idx].deviceStatus = this.callingParty.deviceStatus;

      idx = this.contacts.findIndex((item) => item.id == this.calledParty.id);
      this.contacts[idx].deviceStatus = this.calledParty.deviceStatus;

      // === UPDATE FIRESTORE STATUSES HERE ===
      await this.addSession(this.currentSession);

      // other party
      this.updateUser(this.calledParty.id as string, {
        deviceStatus: DeviceStatus.RINGING,
        sessionInvite: {
          ...this.currentSession,
          otherParty: {
            id: this.self.id,
            fullname: this.self.fullname,
            photo: this.self.photo ? this.self.photo : "",
            // hospital: "GENERAL HOSPITAL",
            hospital: this.hospitalLogged.hospitalName,
            appRole: this.self.appRole
          },
        },
      });

      // self
      this.updateUser(this.self.id as string, {
        deviceStatus: DeviceStatus.DIALING,
        sessionInvite: {
          ...this.currentSession,
          otherParty: {
            id: this.calledParty.id,
            fullname: this.calledParty.fullname,
            photo: this.calledParty.photo ? this.calledParty.photo : "",
            // hospital: "GENERAL HOSPITAL",
            hospital: this.hospitalLogged.hospitalName,
            appRole: this.calledParty.appRole
          },
        },
      }).then(() => {
        this.callLoading = false;
      });
     
    },

    // this cancel or reject call request (calls not yet established)
    // Note: you cant sent Zoom Commands here, since the Session is not yet established!!!
    // 1 update users deviceStatus, remove  sessionInvite
    // 2. and leave session
    // 3. stop ringing?
    async cancelCall() {
      console.log("=== REJECT CALL ===");
      const currentDate = new Date();
      const timestamp = currentDate.getTime();

      // === UPDATE FIRESTORE STATUSES HERE ===
      // update session
      await this.updateSession(this.currentSession.id as string, {
        status: SessionStatus.CALL_CANCELLED,
        sessionRejectID: this.self.id,
        callEnd: timestamp,
      });

    },

    async acceptCall(party: Person, ack = false) {
      // === we should use the Session passed in sessionInvite ===
      if(this.self.deviceStatus !== DeviceStatus.INCALL){
        this.initZoom();

        const session = {} as Session;
        session.topic = this.self.sessionInvite?.topic as string;
        session.providerId = this.self.sessionInvite?.providerId as string;
        session.password = this.self.sessionInvite?.password as string;
        session.status = this.self.sessionInvite?.status as SessionStatus;
        session.idleTimeout = this.self.sessionInvite?.idleTimeout as number;
        session.zoomHostUserID = this.self.sessionInvite?.sessionHostID as number | undefined;

        // === zoom session token for calling party
        // - user.fullname
        // - session.topic
        // - session.password
        //
        // IN this case this is for the CALLING PARTY / SELF
        // the other Party ACCEPTING the CALL must generate also their token      
        const session_token = this.generateJWTSignature(
          this.self,
          session,
          false
        );
        
        this.self.session_token = session_token; 

        // JOIN SESSION
        // INITIALIZE EVENT LISTNERS
        // INITIALIZE AUDIO
        this.ts.push(performance.now());
        await this.joinZoomSession(this.self, session);
        this.ts.push(performance.now());
        console.log('=== TS:', JSON.stringify(this.ts));
        console.log('=== JOINZOOM SESSION (MS)', this.ts[1] - this.ts[0]);

        // SELF Always accept the Call
        this.setSession(session);

        this.updateUser(this.self.id as string, {
          deviceStatus: DeviceStatus.INCALL,
        });

        this.stopRingtone();
      }
    },

    async getSessionParticipants() {
      this.zoomParticipants = this.zoomClient.getAllUser();
      console.log(
        "=== ZOOM SESSION PARTICIPANBTS ===",
        JSON.stringify(this.zoomParticipants)
      );

      let width = 1080;
      let height = 540;
      await this.zoomParticipants?.forEach(async (part) => {
        if (part.userId == this.zoomClient.getCurrentUserInfo().userId) {
          width = 1080 / 3;
          height = 540 / 3;
        }
        if (!part.bVideoOn) {
          await this.renderVideo(this.videoCanvas, part.userId, width, height);
        }
      });
    },

    async joinZoomSession(party: Person, payload: Session, ack=true) {
      /* ************************************************
       *              ZOOM JOIN SESSION                 *
       *  NEEDS:                                        *
       *  - topic                                       *
       *  - token                                       *
       *  - username                                    *
       *  - password                                    *
       *  Note: Used current self for host.             *
       * ********************************************** */
      await this.zoomClient
        .join(
          payload.topic,
          party.session_token,
          party.fullname,
          payload.password
        ).catch((error) => {
          console.log(error);
        });
        this.zoomStream = this.zoomClient.getMediaStream();
        this.zoomCmd = this.zoomClient.getCommandClient(); 

        this.initClientEventListeners();
        await this.startAudio();
        

        if(this.self.id != payload.sessionHostID){
          try{
            this.zoomCmd.send(
              ZoomCommand.CALL_ACCEPTED, this.zoomClient.getSessionHost()?.userId
            );
            
          }catch(e){
            console.error('ERROR ON SENDING COMMAND', e);
          }
        }     
        const userId:number|undefined = this.zoomClient.getSessionHost()?.userId;
        return userId;
    },

    startVideo: async function (canvasElem: any, videoElem: any, type: string) {
      console.log("startVideo...", canvasElem, videoElem);
      // zoomStream.startVideo(payload);

      // if desktop Chrome or Edge (Chromium) with SharedArrayBuffer not enabled

      console.log("try SELF VIEW ...");
      if (type == "video") {
        console.log("USING VIDEO ELEMENT");

        this.zoomStream
          .startVideo({ videoElement: videoElem })
          .then()
          .catch((error) => {
            console.log("error...", error);
          });
      }

      if (type == "canvas") {
        console.log("USING CANVAS ELEMENT");

        this.zoomStream.startVideo();
        this.zoomStream
          .renderVideo(
            canvasElem,
            this.zoomClient.getCurrentUserInfo().userId,
            320,
            180,
            0,
            0,
            VideoQuality.Video_360P
          )
          .then()
          .catch((error) => {
            console.log("error...", error);
          });
      }
    },
    toggleRemoteAudio: async function () {
      this.remoteIsMuted = !this.remoteIsMuted;
      this.zoomCmd.send(ZoomCommand.TOGGLE_MIC,  this.currentSession.zoomOtherUserID);
    },

    toggleRemoteVideo: async function () {
      this.zoomCmd.send(ZoomCommand.TOGGLE_VIDEO, this.currentSession.zoomOtherUserID);
    },

    toggleSelfVideo: async function () {
      console.log("isCameraOn?", this.isWebCamOn);
      this.isWebCamOn = !this.isWebCamOn;
      this.videoLogoIOS = this.isWebCamOn
        ? videocamOutline
        : videocamOffOutline;
      this.videoLogoA = this.isWebCamOn ? videocamSharp : videocamOffSharp;
      if (this.isWebCamOn) {
        this.renderVideo(this.selfCanvas, this.self.zoom_user_id, 360, 180);
      } else {
        this.stopRenderVideo(this.selfCanvas, this.self.zoom_user_id);
      }
    },

    toggleVideo: async function (
      option: boolean,
      canvas: HTMLCanvasElement,
      participant: number,
      width: number,
      height: number
    ) {
      if (option) {
        this.renderVideo(canvas, participant, width, height);
      } else {
        this.stopRenderVideo(canvas, participant);
      }
    },

    async renderVideo(
      canvas: HTMLCanvasElement,
      participant: number,
      width: number,
      height: number
    ) {
      if (participant === this.self.zoom_user_id) {
        await this.zoomStream.startVideo();
      }

      await this.zoomStream.renderVideo(canvas, participant, width, height, 0, 0, 3);
    },

    async stopRenderVideo(canvas: HTMLCanvasElement, participant: number) {
      console.warn("SELF: " + this.self.zoom_user_id + "PARTI: " + participant);
      try {
        if (participant === this.self.zoom_user_id) {
          await this.zoomStream.stopVideo();
        } else {
          await this.zoomStream.clearVideoCanvas(canvas);
        }
        await this.zoomStream.stopRenderVideo(canvas, participant);
      } catch (error) {
        console.log("error", error);
      }
    },

    async startAudio() {
      console.log("====AUDIO STARTING====", this.zoomStream.isAudioMuted());
      await this.zoomStream.startAudio();

      try {
        if (this.zoomStream.isAudioMuted()) {
          this.zoomStream.unmuteAudio();
          console.log("====AUDIO UNMUTED====");
        }
      } catch (e) {
        console.log(e);
      }
    },

    // option - true to mute false to unmute
    async toggleAudio() {
      this.isMuted = !this.isMuted;

      if (this.zoomStream.isAudioMuted() == true) {
        try {
          await this.zoomStream.unmuteAudio();
        } catch (error) {
          console.log(
            " === TOGGLE AUDIO (forcing...) === ",
            JSON.stringify(error)
          );
          // await this.zoomStream.startAudio();
          await this.zoomStream.unmuteAudio();
        }
        // await zoomStream.startAudio();
      } else {
        try {
          await this.zoomStream.muteAudio();
        } catch (error) {
          console.log(
            " === TOGGLE AUDIO (forcing...) === ",
            JSON.stringify(error)
          );
          // await this.zoomStream.startAudio();
          await this.zoomStream.muteAudio();
        }
        // await zoomStream.stopAudio();
      }

      console.log(
        " === TOGGLE AUDIO (now) === ",
        this.zoomStream.isAudioMuted() ? "MUTED" : "ACTIVE"
      );
    },
    terminatedByHost: async function () {
      console.log("=== TERMINATED BY HOST ===");
      await this.zoomCmd.send(ZoomCommand.CALL_TERMINATED, this.currentSession.zoomOtherUserID).then(async () => {
        setTimeout(async () => {
          await this.terminateCall(this.selfCanvas, this.selfVideo);
          this.ringtone = new Audio();
        }, 1000);
      });
      if (this.self.appRole == AppRole.ELDERLY) {
        console.log("ROUTER:", router);
        router.push({ path: "/elderly/standby" });
      }
      
    },

    requestEndCall: async function () {
      console.log("GET HOST", this.zoomClient.getSessionHost()?.userId);
      if (this.isWebCamOn) {
        await this.myToggleVideo(this.selfCanvas, this.selfVideo);
        await this.zoomStream.clearVideoCanvas(this.videoCanvas, "blue");
      }
      await this.zoomStream.stopAudio();
      this.isMuted = false;
      this.remoteIsMuted = false;
      this.zoomCmd.send(ZoomCommand.END_CALL, this.zoomClient.getSessionHost()?.userId);
      // .then(() => {
      //   this.destroyClientEventListeners();
      //   this.zoomClient.leave().finally(async () => {
      //     ZoomVideo.destroyClient();
      //   });
      // });
      
      if (this.self.appRole == AppRole.ELDERLY) {
        console.log("=== ROUTER: ===", router);
        console.log('=== STEP X : RELOADING');
        this.reloadPage = !this.reloadPage;
        setTimeout(async () => {
          await this.updateUser(this.self.id as string, {
            deviceStatus: DeviceStatus.ONLINE,
            sessionInvite: {} as Session,
          });
        }, 2000);
        router.push({ path: "/elderly/standby" });
      }
    },

    rejectCall: async function() {
      console.log('=== CALL IS REJECTED by', this.currentSession.sessionRejectID);

      setTimeout(async () => {
        await this.zoomClient.leave(true);
      }, 1000)
      

      // other party
      await this.updateUser(this.calledParty.id as string, {
        deviceStatus: DeviceStatus.ONLINE,
        sessionInvite: {} as Session,
      });

      // self
      await this.updateUser(this.self.id as string, {
        deviceStatus: DeviceStatus.ONLINE,
        sessionInvite: {} as Session,
      });

      // clean up
      this.currentSession = {} as Session;
      this.callingParty = {} as Person;
      this.calledParty = {} as Person;
      this.callParty = [];

    },

    terminateCall: async function (
      canvasElem: HTMLCanvasElement,
      videoElem: HTMLVideoElement,
    ) {

      // stop devices
      this.stopRingtone();

      // toggle video
      if (this.isWebCamOn) {
        await this.myToggleVideo(canvasElem, videoElem);
        await this.zoomStream.clearVideoCanvas(this.videoCanvas, "blue");
      }

      await this.zoomStream.stopAudio();
      

      this.isMuted = false;
      this.remoteIsMuted = false;
      this.reloadPage = !this.reloadPage;  

      setTimeout(async () => {
        await this.zoomClient.leave(true);
      }, 1000)
      // ZoomVideo.destroyClient();
      // INITIALIZE ZOOM INSTANCES TO NEW
      // this.zoomClient = VideoClient;
      this.evtPayload = {};
      this.zoomStream = Stream;
      this.zoomCmd = CommandChannel;
     

      this.self.deviceStatus = DeviceStatus.ONLINE;
      this.self.sessionInvite = {} as Session;

      const currentDate = new Date();
      const timestamp = currentDate.getTime();

      // === UPDATE FIRESTORE STATUSES HERE ===
      // session 
      await this.updateSession(this.currentSession.id as string, {
        status: SessionStatus.COMPLETED,
        callEnd: timestamp,
      });

      // other party
      // await this.updateUser(this.calledParty.id as string, {
      //   deviceStatus: DeviceStatus.ONLINE,
      //   sessionInvite: {} as Session,
      // });

      // self
      await this.updateUser(this.self.id as string, {
        deviceStatus: DeviceStatus.ONLINE,
        sessionInvite: {} as Session,
      });


      // clean up
      this.callingParty = {} as Person;
      this.calledParty = {} as Person;
      this.callParty = [];
    },

    //  for debugging
    toggleAppRole() {
      // this.appRole == AppRole.ELDERLY ? AppRole.HOSPITAL : AppRole.ELDERLY
      console.log("TOGGLE APP ROLE...", this.self.appRole, this.self);
      localStorage.setItem(
        "session",
        JSON.stringify({ appRole: this.appRole, self: this.self })
      );
    },

    myToggleAudio: async function () {
      if (this.zoomStream.isAudioMuted() == true) {
        await this.myStartAudio();
      } else {
        await this.myStopAudio();
      }

      console.log(
        "=== MIC STATUS ====",
        this.zoomClient.getCurrentUserInfo().muted ? "OFF" : "ON"
      );
    },

    myStartAudio: async function () {
      // zoomStream.startAudio({ autoStartAudioInSafari: true });
      this.zoomStream.startAudio();
      if (this.zoomStream.isAudioMuted()) {
        this.zoomStream.unmuteAudio(this.zoomClient.getCurrentUserInfo().userId);
      }
      console.log(
        "=== MIC STATUS ====",
        this.zoomStream.isAudioMuted(this.zoomClient.getCurrentUserInfo().userId)
          ? "OFF"
          : "ON"
      );
    },

    myStopAudio: async function () {
      // zoomStream.stopAudio();
      if (this.zoomStream.isAudioMuted() == false) {
        this.zoomStream.muteAudio(this.zoomClient.getCurrentUserInfo().userId);
      }
      console.log(
        "=== MIC STATUS ====",
        this.zoomStream.isAudioMuted() ? "OFF" : "ON"
      );
    },

    myToggleVideo: async function (
      canvasElem: HTMLCanvasElement,
      videoElem: HTMLVideoElement
    ) {
      if (this.zoomClient.getCurrentUserInfo().bVideoOn == true) {
        await this.myStopVideo(canvasElem, videoElem);
      } else {
        await this.myStartVideo(canvasElem, videoElem);
      }

      console.log(
        "=== TOGGLE VIDEO (now) ====",
        this.zoomClient.getCurrentUserInfo().bVideoOn ? "ON" : "OFF"
      );
    },

    // Start Video this accept Canvas and Video element
    // apparently the mobile needs to use video element
    myStartVideo: async function (
      canvasElem: HTMLCanvasElement,
      videoElem: HTMLVideoElement
    ) {
      console.log("=== PLATFORMS ===", getPlatforms());
      if (this.zoomClient.getCurrentUserInfo().bVideoOn == true) {
        console.log("=== DO NOTHING! Video Already On === ");
        return;
      }

      console.log("=== CROSS ORIGIN ISOLATED ===", window.crossOriginIsolated);

      this.selfViewType = "canvas";

      // if not self view was not correctly guessed yet, try alternative
      // if mobile use video element else canvas
      if (this.self.appRole === AppRole.ELDERLY) {
        this.selfViewType = "video";
      }

      console.log("=== myStartVideo ===", this.selfViewType as string);

      const cameras = this.zoomStream.getCameraList();
      console.log("=== CAMERAS ===", JSON.stringify(cameras));
      console.log(
        "=== ACTIVE CAMERA ===",
        JSON.stringify(this.zoomStream.getActiveCamera())
      );

      console.log(
        "=== BROWSER SUPPORTS OffscreenCanvas ===",
        this.isSupportOffscreenCanvas
      );
      console.log(
        "=== ZOOM STREAM SUPPORTS MultipleVideos ===",
        this.zoomStream.isSupportMultipleVideos()
      );

      const isUsingVideoElementToStartVideo =
        this.isSupportOffscreenCanvas && !this.zoomStream.isSupportMultipleVideos();
      const isRenderingSingleVideoOnCanvas =
        !this.isSupportOffscreenCanvas && !this.zoomStream.isSupportMultipleVideos();

      const canvas = isRenderingSingleVideoOnCanvas
        ? this.selfCanvas
        : this.videoCanvas;

      if (isUsingVideoElementToStartVideo) {
        console.log("=== START VIDEO (USING VIDEO ELEMENT) ===");
        // this also renders the video
        videoElem.style.display = "block";
        videoElem.style.width = this.selfViewWidth.toString();
        // videoElem.style.left = "50%";
        await this.zoomStream.startVideo({ videoElement: videoElem });
      } else {
        console.log("=== START VIDEO ===");
        await this.zoomStream.startVideo().catch((error) => {
          console.error("=== START VIDEO (error) ===", JSON.stringify(error));
        });
        // render the video in canvas
        if (isRenderingSingleVideoOnCanvas) {
          // render self view in separate canvas
          const h = Math.round((this.canvasHeight * 0.2) / 1.2);
          const w = Math.round((h * 16) / 9);

          console.log("=== SELF VIEW SIZE (w,h)===", w, h, canvas);
          this.zoomStream.renderVideo(
            canvas,
            this.zoomClient.getCurrentUserInfo().userId,
            this.selfViewWidth,
            this.selfViewHeight,
            0,
            0,
            VideoQuality.Video_360P
          );
        } else {
          // render self view in main canvas
          const h = Math.round((this.canvasHeight * 0.2) / 1.2);
          const w = Math.round((h * 16) / 9);

          console.log("=== SELF VIEW SIZE (w,h)===", w, h, canvas);
          this.zoomStream.renderVideo(
            canvas,
            this.zoomClient.getCurrentUserInfo().userId,
            this.selfViewWidth,
            this.selfViewHeight,
            0,
            0,
            VideoQuality.Video_360P
          );
        }
      }

      // IMPORTANT: if successful locked the SelfViewType to the current value
      if (this.zoomClient.getCurrentUserInfo().bVideoOn == true) {
        SelfViewLocked = true;
      }

      // display status
      console.log(
        "=== USER STATUS ===",
        JSON.stringify(this.zoomClient.getCurrentUserInfo())
      );
      this.isWebCamOn = this.zoomClient.getCurrentUserInfo().bVideoOn;
    },

    myStopVideo: async function (
      canvasElem: HTMLCanvasElement,
      videoElem: HTMLVideoElement
    ) {
      if (this.zoomClient.getCurrentUserInfo().bVideoOn == false) {
        console.log("=== DO NOTHING! Video Already Off ===");
        return;
      }

      const cameras = this.zoomStream.getCameraList();
      console.log(
        "=== CAMERAS ===",
        JSON.stringify(cameras),
        canvasElem,
        videoElem
      );
      console.log(
        "=== ACTIVE CAMERA ===",
        JSON.stringify(this.zoomStream.getActiveCamera())
      );
      if (this.selfViewType == "canvas") {
        console.log("=== SELFVIEW is CANVAS ===");
        await this.zoomStream.stopRenderVideo(
          this.videoCanvas,
          this.zoomClient.getCurrentUserInfo().userId
        );

        await this.zoomStream.clearVideoCanvas(this.videoCanvas, "blue");
      }

      await this.zoomStream.stopVideo();

      // display status
      console.log(
        "=== USER STATUS ===",
        JSON.stringify(this.zoomClient.getCurrentUserInfo())
      );
      this.isWebCamOn = this.zoomClient.getCurrentUserInfo().bVideoOn;
    },

    // === FIRESTORE ===

    // USERS
    async getUsers() {
      console.log("=== GET USERS ===");
      const q = query(collection(db, "users"));
      const unsub = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          let idx: number;
          if (change.type === "added") {
            // console.log("New User: ", change.doc.data());

            const rand1or2 = Math.floor(Math.random() * 2) + 1;
            const randRole =
              rand1or2 === 1 ? AppRole.ELDERLY : AppRole.HOSPITAL;

            this.contacts.push({
              id: change.doc.id,
              appRole: randRole,
              ...change.doc.data(),
            } as Person);

            // assign first contact as SELF
            if (this.contacts.length == 1) {
              Object.assign(this.self, this.contacts[1]);
            }
          }
          if (change.type === "modified") {
            console.log("Modified User: ", change.doc.data());

            console.log(
              "==== SESSION HOST ID: ",
              this.self.sessionInvite?.sessionHostID,
              this.self.id
            );

            idx = this.contacts.findIndex((item) => item.id == change.doc.id);
            this.contacts.splice(idx, 1, {
              id: change.doc.id,
              ...change.doc.data(),
            } as Person);

            // hmmm DIRTY HOOK ---> UPDATE SELF
            if (this.self.id == change.doc.id) {
              Object.assign(this.self, change.doc.data());
              
              if (
                (this.self.deviceStatus === DeviceStatus.RINGING ||
                  this.self.deviceStatus === DeviceStatus.DIALING) &&
                this.self.appRole === AppRole.HOSPITAL
              ) {
                console.log(
                  "Firebase Trigger: (SELF UPDATED) ======= YOU ARE INVITED TO ACCEPT CALL / JOIN THE MEETING ======",
                  this.self.fullname,
                  this.self.deviceStatus,
                  this.self.sessionInvite
                );
                router.push({ path: "/initiate" });
              }

              if(this.self.id !== this.self.sessionInvite?.sessionHostID && this.self.deviceStatus === DeviceStatus.RINGING){
                this.currentSession = this.self.sessionInvite || {} as Session;
              }

              if(this.isRingtonePlaying && this.self.deviceStatus === DeviceStatus.ONLINE){
                this.stopRingtone();
              }

              if( (this.self.deviceStatus === DeviceStatus.DIALING ||
                this.self.deviceStatus === DeviceStatus.RINGING ||
                this.self.deviceStatus === DeviceStatus.FORCED) &&  this.self.appRole === AppRole.ELDERLY
              ) {
                console.log('=== ROUTING TO INIATIATE ===');
                router.push({ path: "/initiate" });
              }

              if(this.self.id === this.self.sessionInvite?.calledParty?.id && this.self.deviceStatus === DeviceStatus.FORCED ){
                clearTimeout(this.autoAnswerId);
                console.log(`=== Timeout ID ${this.autoAnswerId} has been cleared ===`);
                setTimeout(this.acceptCall, 3000);
              }
            }
          }
          if (change.type === "removed") {
            console.log("Removed User: ", change.doc.data());
            idx = this.contacts.findIndex((item) => item.id == change.doc.id);

            this.contacts.splice(idx, 1);
          }
        });
      });

      // you may need unsubscribe from Firestore later
      // unsub();
    },
    async updateUser(id: string, payload: any) {
      console.log("updateUser...", id, payload);
      const docRef = doc(db, "users", id);
      await updateDoc(docRef, payload);
    },
    async addUser(payload: Person) {
      try {
        const docRef = await addDoc(collection(db, "users"), payload);
        console.log("Firestore: add user successful...", docRef);
      } catch (e) {
        console.log("Firestore: Error adding document...", e);
      }
    },
    async deleteUser(id: any) {
      try {
        const docRef = doc(db, "users", id);
        await deleteDoc(docRef);
      } catch (e) {
        console.log("Firestore: Error deleting document...", e);
      }
    },

    async getUserByID(id: any) {
      let user: Person;
      try {
        console.log("getUserByID...", id);
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log(docSnap.data());
          user = docSnap.data() as Person;
          console.log(user);
          return user;
        } else {
          console.log("Document does not exist");
        }
      } catch (e) {
        console.log("Firestore: Error fetching user...", e);
      }
    },

    //HOSPITAL
    async getHospital() {
      const q = query(collection(db, "hospital"));
      const unsub = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          let idx: number;
          if (change.type === "added") {
            // console.log("New User: ", change.doc.data());

            this.hospitals.push({
              id: change.doc.id,
              ...change.doc.data(),
            } as Hospital);
          }
          if (change.type === "modified") {
            console.log("Modified Hospital: ", change.doc.data());
            if (this.hospitalLogged.id == change.doc.id) {
              Object.assign(this.hospitalLogged, change.doc.data());
            }



            idx = this.hospitals.findIndex((item) => item.id == change.doc.id);
            this.hospitals.splice(idx, 1, {
              id: change.doc.id,
              ...change.doc.data(),
            } as Hospital);
          }
        });
      });
    },
    async updateHospital(id: any, payload: any) {
      console.log("updateHospital");
      try {
        const docRef = doc(db, "hospital", id);
        console.log(docRef);
        await updateDoc(docRef, payload);
        console.log("updateHospital success...", id, payload);
      } catch (e) {
        console.log("updateHospital error!!", e);
      }
    },
    async addHospital(payload: Hospital) {
      try {
        const docRef = await addDoc(collection(db, "hospital"), payload);
        console.log("Firestore: add hospital successful...", docRef);
      } catch (e) {
        console.log("Firestore: Error adding document...", e);
      }
    },
    async deleteHospital(id: any) {
      try {
        await deleteDoc(id);
      } catch (e) {
        console.log("Firestore: Error deleting document...", e);
      }
    },

    async getHospitalByID(id: any) {
      let hospital: Hospital;
      try {
        console.log("getHospitalByID...", id);
        const docRef = doc(db, "hospital", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          hospital = docSnap.data() as Hospital;
          console.log("=== GET HOSPITAT BY ID RETURN ===", hospital);
          return hospital;
        } else {
          console.log("Document does not exist");
        }
      } catch (e) {
        console.log("Firestore: Error fetching hospital...", e);
      }
    },

    // SESSIONS
    async getSessions() {
      const q = query(collection(db, "sessions"));
      const unsubSession = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          let idx: number;
          if (change.type === "added") {
            // console.log("New Session: ", change.doc.data());
            this.sessions.push({
              id: change.doc.id,
              ...change.doc.data(),
            } as Session);
          }
          if (change.type === "modified") {
            console.log("Modified Session: ", change.doc.data());
            if (this.currentSession.id === change.doc.id) {
              Object.assign(this.currentSession, change.doc.data());
              if(this.currentSession.sessionRejectID && this.self.id !== this.currentSession.sessionRejectID){
                console.log('=== SHOW CALL TERMINATE INFO ===', this.autoAnswerId);
                clearTimeout(this.autoAnswerId);
                console.log(`Timeout ID ${this.autoAnswerId} has been cleared`);
                this.isCallTerminated = true;
              }
              if(this.self.id === this.currentSession.sessionHostID && this.currentSession.sessionRejectID){
                console.log('=== HOST LEAVING ZOOM SESSION ===', this.currentSession.sessionRejectID)
                this.rejectCall();
              }
            }
            // idx = this.sessions.findIndex((item) => item.id == change.doc.id);
            // this.sessions.splice(idx, 1, {
            //   id: change.doc.id,
            //   ...change.doc.data(),
            // } as Session);
            unsubSession();
          }
          if (change.type === "removed") {
            console.log("Removed Session: ", change.doc.data());
            idx = this.sessions.findIndex((item) => item.id == change.doc.id);

            this.sessions.splice(idx, 1);
          }
        });
      });

      // you may need unsubscribe from Firestore later
      // unsubSession();
    },

    async updateSession(id: string, payload: any) {
      console.log("updateSession...", id, payload);
      const docRef = doc(db, "sessions", id);
      await updateDoc(docRef, payload);
    },

    async addSession(payload: Session) {
      console.log("addSession....");
      console.log("payload", payload, this.self);
      try {
        const docRef = await addDoc(collection(db, "sessions"), payload);
        console.log("Firestore: Add Session successful...", docRef);
        console.log("SESSION DOCREF ID: ",docRef.id);
        this.currentSession = payload;
        this.currentSession.id = docRef.id;
      } catch (e) {
        console.log("Firestore: Error adding document...", e);
      }
     
    },

    async deleteSession(id: any) {
      try {
        await deleteDoc(id);
        console.log("Firestore: Delete Session successful...");                                                                               
      } catch (e) {
        console.log("Firestore: Error adding document...", e);
      }
    },

    // === WARNING !!! updates Firestore, use with cost in mind ===
    _createDummyContacts(count = 1) {
      return;
      // some dummy contacts  
      for (let i = 0; i < count; i++) {
        this.addUser({
          fullname: faker.name.firstName() + " " + faker.name.lastName(),
          age: +faker.random.numeric(2),
          gender: +faker.random.numeric(1) < 4 ? "Male" : "Female",
          phone: faker.phone.phoneNumber("555-###-###"),
          address: faker.address.streetAddress() + " " + faker.address.city(),
          photo: faker.image.avatar(),
        } as Person);
      }
    },

    async createJapaneseData() {
      console.log("createJapaneseData");

      const arrJapaneseData = [] as Array<Person>;
      const staff1 = {} as Person;
      const staff2 = {} as Person;
      const staff3 = {} as Person;

      const elderly1 = {} as Person;
      const elderly2 = {} as Person;
      const elderly3 = {} as Person;

      // hospital staff 1
      staff1.fullname = "山下 久美子";
      staff1.age = 25;
      staff1.gender = "女";
      staff1.address = "やました　くみこ";
      staff1.phone = "999-9999-9991";
      staff1.appRole = AppRole.HOSPITAL;
      staff1.photo = await this.getImageURLFromFirebase(
        "gs://mt-zoom.appspot.com/山下 久美子.png"
      );
      // add to array
      arrJapaneseData.push(staff1);

      // hospital staff 2
      staff2.fullname = "早川 稔";
      staff2.age = 37;
      staff2.gender = "男";
      staff2.address = "はやかわ　みのる";
      staff2.phone = "999-9999-9992";
      staff2.appRole = AppRole.HOSPITAL;
      staff2.photo = await this.getImageURLFromFirebase(
        "gs://mt-zoom.appspot.com/早川 稔.png"
      );
      // add to array
      arrJapaneseData.push(staff2);

      // hospital staff 3
      staff3.fullname = "佐藤 美咲";
      staff3.age = 33;
      staff3.gender = "女";
      staff3.address = "さとう　みさき";
      staff3.phone = "999-9999-9993";
      staff3.appRole = AppRole.HOSPITAL;
      staff3.photo = await this.getImageURLFromFirebase(
        "gs://mt-zoom.appspot.com/佐藤 美咲.png"
      );
      // add to array
      arrJapaneseData.push(staff3);

      // elderly 1
      elderly1.fullname = "加藤 一夫";
      elderly1.age = 78;
      elderly1.gender = "男";
      elderly1.address = "かとう　かずお";
      elderly1.phone = "555-5555-5551";
      elderly1.appRole = AppRole.ELDERLY;
      elderly1.photo = await this.getImageURLFromFirebase(
        "gs://mt-zoom.appspot.com/加藤一夫.png"
      );
      // add to array
      arrJapaneseData.push(elderly1);

      // elderly 2
      elderly2.fullname = "木下 早苗";
      elderly2.age = 82;
      elderly2.gender = "女";
      elderly2.address = "きのした　さなえ";
      elderly2.phone = "555-5555-5552";
      elderly2.appRole = AppRole.ELDERLY;
      elderly2.photo = await this.getImageURLFromFirebase(
        "gs://mt-zoom.appspot.com/木下 早苗.png"
      );
      // add to array
      arrJapaneseData.push(elderly2);

      // elderly 3
      elderly3.fullname = "安藤 くみ";
      elderly3.age = 74;
      elderly3.gender = "女";
      elderly3.address = "あんどう　くみ";
      elderly3.phone = "555-5555-5553";
      elderly3.appRole = AppRole.ELDERLY;
      elderly3.photo = await this.getImageURLFromFirebase(
        "gs://mt-zoom.appspot.com/安藤 くみ.png"
      );
      // add to array
      arrJapaneseData.push(elderly3);

      for (let i = 0; i < arrJapaneseData.length; i++) {
        console.log("full name: " + arrJapaneseData[i].fullname);
        console.log("address: " + arrJapaneseData[i].address);
        console.log("photo: " + arrJapaneseData[i].photo);
        console.log("role: " + arrJapaneseData[i].appRole);

        // add user to firebase
        this.addUser(arrJapaneseData[i]);
      }
    },

    // deletion of japanese data
    deleteData() {
      const ids = [
        "DvGmeXzzRwhnLvGfLoUq",
        "wcPBTKhcTWvMFeW37q2r",
        "ysWuwAsSlV0P9EK7aVr9",
        "yeX7Fb7skkWIJE0OPkHv",
        "eQHHIsbO7cHpeBiRMGVf",
        "TMP1HaKBXLt1hqALKoLw",
      ];

      for (let i = 0; i < ids.length; i++) {
        console.log("USER DELETED: " + ids[i]);
        this.deleteUser(ids[i]);
      }
    },
    // Gets the downloaded url from firebase
    // Returns: url (string)
    async getImageURLFromFirebase(filename: string) {
      console.log("getImageURLFromFirebase");
      let downloadURL = "" as string;
      // Create a reference with an initial file path and name
      const storage = getStorage();
      const gsReference = ref(storage, filename);
      // ge download url
      await getDownloadURL(gsReference).then((url) => {
        downloadURL = url;
      });

      return downloadURL;
    },

    // FOR RINGTONE
    // initialize ringtone file
    initRingtone(file: string) {
      this.ringtone = new Audio(file);
      // loop ringtone
      this.ringtone.loop = true;
      console.log("Audio CREATED");
    },

    // play ringtone
    playRingtone() {
      console.log("BOOLEAN RINGTONE: " + this.isRingtonePlaying);
      if (!this.isRingtonePlaying) {
        // add then catch
        this.ringtone
          .play()
          .then(() => {
            console.log("Ringtone playing...");
            this.isRingtonePlaying = true;
          })
          .catch(() => {
            console.log("ERROR PLAYING");
            this.isRingtonePlaying = false;
          });
      }
    },

    stopRingtone() {
      // pause ringtone
      this.ringtone.pause();

      console.log("Ringtone paused!");
      this.isRingtonePlaying = false;
    },

    // CHECK IF RUNNING IN MOBILE/DESKTOP
    isMobile() {
      if (isPlatform("mobile")) {
        console.log("=== RUNNING IN MOBILE ===");
        return true;
      } else {
        console.log("=== NOT RUNNING IN MOBILE ===");
        return false;
      }
    },
    // Update navbar header: staff management
    updateNavbarHeader(header: string) {
      console.log("updateNavbarHeader: " + header);
      this.staffNavBarHeader = header;
    },

    // upload image to firestore
     uploadImageToFirestore : async function(file: Blob, filename: string) : Promise<string> {
      try{
          console.log("uploadImageToFirestore");
          const storagePath = "gs://"+ process.env.VUE_APP_FB_STORAGE_BUCKET +"/";
    
          // Create a reference with an initial file path and name
          const storage = getStorage();
          const gsReference = ref(storage, "mtzoom-dev/"+filename);
          let fileLocation = "";
          let fullpath = "";
    
          // 'file' comes from the Blob or File API
          await uploadBytes(gsReference, file).then((snapshot) => {
            fileLocation = storagePath + snapshot.ref.fullPath;
          });

          try{
            // download image url
            fullpath = await this.getImageURLFromFirebase(
              fileLocation
            );
            console.log("IMAGEEE: " + fullpath);
          }catch(e){
            console.log("Firestore: Error getting url from firebase...", e);
            fullpath = "";
          }

          return fullpath;

      }catch(e){
          console.log("Firestore: Error uploading...", e);
          return "";

      }
      
    },

    // UPLOAD IMAGE FUNCTION
    uploadProfile: async function (selectedFile: File): Promise<string> {
      console.log("uploadImg");
      let fullpath = "";
      try { 
          const currentDate = new Date();
        const dayJs = dayjs(currentDate).format("YYYYMMDDHHmmss");
        const filename = dayJs + "_" + selectedFile.name;
        console.log("filename: " + filename);

       

        await this
          .uploadImageToFirestore(selectedFile, filename)
          .then((path) => {
            fullpath = path;
          });

        return fullpath;

      } catch(e){
        console.log('ERROR UPLOADING PHOTO');
        return fullpath = "";
      }
     
      
    },

    // Custom Auth #12297: signInUser and signInUser2 are for testing purposes only
    async signinUser2(id: string, password: string) {
      //check local storage
        try {
          let hospitalUser = {} as Hospital;
          const q = query(collection(db, "hospital"), where('hospitalId', '==', id));

          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            hospitalUser = doc.data() as Hospital
            hospitalUser.id = doc.id
          });
          if(!querySnapshot.empty){
            console.log('=== hospitalUser ===')
            console.log(hospitalUser)    
            if(hospitalUser.password == password){
              this.hospitalLogged = hospitalUser;
                  this.hospitalLogged.id = hospitalUser.id;
                  this.isHospitalLogged = true;
                  localStorage.setItem(
                    "hospital_session",
                    JSON.stringify({ isHospitalLogged: this.isHospitalLogged, hospitalLogged: this.hospitalLogged, isAdmin: false })
                  )
                  router.push({ path: "/menu" });
                  return hospitalUser.hospitalName
            } else {
              return 0
            }
          }else{
            return 1
          }
        } catch (e) {
          console.log("Firestore: Error fetching Hospital...", e);
        }
    },    
    //signOut hospital #12297
    async signOut()
    {
      console.log('signOut')
      localStorage.setItem(
        "hospital_session",
        JSON.stringify({ isHospitalLogged: false, hospitalLogged: '', isAdmin: false })
      )

      // Logut also all hospital user/elderly
      if(this.self.id){
        if(this.self.appRole === AppRole.HOSPITAL){
          this.updateUser(this.self.id as string, {
            deviceStatus: DeviceStatus.OFFLINE,
            isHospital: false,
            sessionInvite: {} as Session,
          });

        } else {
          this.updateUser(this.self.id as string, {
            deviceStatus: DeviceStatus.OFFLINE,
            sessionInvite: {} as Session,
          });
        }
        
        this.setSelf({} as Person)
      }
      
      this.isHospitalLogged = false;
      this.hospitalLogged = {} as Hospital;
    },

    signOutStaff(id: string){
      console.log('signOutStaff')
      localStorage.setItem(
        "session",
        JSON.stringify({ appRole: this.appRole, self: {} as Person })
      )
      this.appRole = AppRole.HOSPITAL;
      this.updateUser(id, {
        deviceStatus: DeviceStatus.OFFLINE,
        isHospital: false});
      this.hospitalIsStandby = false;
      this.setSelf({} as Person)
      router.push({ path: "/contacts/staff" });
    },

    // FOR PROFILE DISPLAY
    profileDisplay(photo: string, pageAppRole?: AppRole): string{
      // return photo;
      // check if photo is undefinder or empty
      if(photo == undefined || photo == "" || photo.includes("undefined")){
        // return default
        if(pageAppRole == AppRole.HOSPITAL){
          return require('@/assets/default-hospital.jpg');
        }else{
          return require('@/assets/default-elderly.png');
        }
      }else{
        return photo;
      }
    },
  },
});
